@import 'style';

.list {
	padding: 80 * $px 0;

	&-title {
		margin-bottom: 25 * $px;
	}
}

.items {
	overflow: hidden;
	list-style: none;
	margin-left: 0;

	@include breakpoint('tablet') {
		display: flex;
		flex-wrap: wrap;
		margin-left: -50px;

		&:global(.mod-fullwidth),
		&.mod-hasimage {
			display: block;
		}
	}

	&-item {
		margin-top: -1px;

		@include breakpoint('tablet') {
			display: inline-block;
			vertical-align: top;
			width: calc(100% / 2);
			padding-left: 50px;

			:global(.mod-fullwidth) &,
			.mod-hasimage & {
				width: 100%;
				display: block;
			}
		}

		&-content {
			position: relative;
			display: flex;
			align-items: center;
			padding: 20 * $px 0;
			border-top: 1px solid $line;

			&-checkmark {
				flex: 0 0 auto;
				background: url('~assets/images/list-checkmark.svg') no-repeat;
				width: 30.6px;
				height: 20px;
				margin-right: 15 * $px;
			}

			&-text {
				@include breakpoint('ipad-land') {
					font-size: 18 * $px;
					line-height: (30/18);
				}
			}
		}
	}
}
