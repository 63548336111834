@import 'style';

.profile {
	&-image {
		position: relative;
		width: 100%;
		aspect-ratio: 1;
	}

	&-content {
		display: block;
	}
}
