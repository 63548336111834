@import 'style';

.footer {
	color: $brown-grey;
	position: relative;
	margin: 0 auto;
	max-width: calc(100% - 30px);

	@include breakpoint('tablet') {
		max-width: calc(100% - 50px);
	}

	@include breakpoint('ipad-land') {
		max-width: 100%;
	}

	&-content {
		@include breakpoint('ipad-land') {
			margin: 0 50px;
		}
	}
}

.social {
	margin: 45 * $px 0;

	&-text,
	&-icons {
		display: inline-block;
		vertical-align: middle;
	}

	&-text + &-icons {
		margin-left: 15 * $px;
	}
}

.bottom {
	font-size: 14 * $px;
	line-height: 1.5;
	margin: 45 * $px 0;

	&-row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-left: -15 * $px;
		margin-top: -15 * $px;

		&-left,
		&-right {
			display: inline-block;
			vertical-align: top;
			padding-left: 15 * $px;
			margin-top: 15 * $px;
		}

		&-right {
			&-text,
			&-logo {
				display: inline-block;
				vertical-align: middle;
			}

			&-logo {
				margin-left: 15 * $px;

				&-img {
					display: block;
					height: 20 * $px;
					max-width: 75 * $px;
				}
			}
		}
	}
}

.links {
	&-item {
		font-weight: 500;
		display: inline-block;
		vertical-align: middle;
		transition: color 0.25s ease-out;

		&:hover {
			color: $dark-grey;
		}
	}

	&-spacer {
		display: inline-block;
		vertical-align: middle;
		margin: 0 5 * $px;
	}
}
