@import 'style';

.header {
	position: relative;
	margin: 0 auto;
	max-width: calc(100% - 30px);

	@include breakpoint('tablet') {
		max-width: calc(100% - 50px);
	}

	@include breakpoint('ipad-land') {
		max-width: 100%;
	}

	&-rowcontainer {
		background-color: $background;
		position: relative;
		z-index: 3;
	}

	&-row {
		height: 64 * $px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include breakpoint('ipad-land') {
			height: 75 * $px;
		}

		&-left {
			line-height: 0;

			@include breakpoint('ipad-land') {
				height: 100%;
			}
		}

		&-right {
			height: 100%;
			flex: 0 0 auto;
			display: flex;
			align-items: center;

			&-link {
				height: 100%;
				position: relative;
				display: flex;
				align-items: center;
				padding: 14 * $px;
				margin-right: 15 * $px;
				transition: all 0.25s ease-out;

				&-text {
					position: relative;
				}

				&:hover,
				&.is-active {
					background-color: white;

					&:after {
						transform: scaleX(1);
						transform-origin: left center;
					}
				}

				&:after {
					content: '';
					background-color: $blue;
					width: 100%;
					height: 4 * $px;
					position: absolute;
					left: 0;
					bottom: 0;
					transform: scaleX(0);
					transform-origin: right center;
					transition: transform 0.25s ease-out;
				}
			}
		}
	}

	&-dropdowncontainer {
		position: relative;
		z-index: 2;
		display: none;

		@include breakpoint('ipad-land') {
			display: block;
		}
	}

	&-button {
		display: none;

		@include breakpoint('ipad-land') {
			display: block;
			margin-left: 30px;
		}
	}
}

.header_home {
	flex: 0 0 auto;
	font-size: 22 * $px;
	display: inline-block;
	vertical-align: middle;
}

.header_logo {
	display: inline-block;
	vertical-align: middle;

	&-img {
		display: block;
		height: 20 * $px;
		max-width: 75 * $px;
	}
}

.header_autocomplete {
	position: relative;
	min-width: 247px;
	max-width: min-content;
}

.header_search {
	position: relative;
	display: inline-block;
	vertical-align: top;
	line-height: 0;

	&-active {
		background-color: white;
		display: flex;
		height: 50 * $px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		&-input {
			height: 100%;
			display: inline-block;
			vertical-align: middle;
			padding: 0 20 * $px;
			border: 1px solid transparent;
			border-right-color: #dedede;
			max-width: calc(100% - #{50 * $px});

			&:focus {
				outline: none;
			}

			&:focus-visible {
				border-color: #dedede;
			}
		}

		&-button {
			display: inline-block;
			vertical-align: middle;
			padding: 15 * $px;
			width: 50 * $px;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&-button {
		display: inline-block;
		vertical-align: middle;
		width: 19 * $px;
		&:hover {
			cursor: pointer;
		}
	}
}

.header_search + .header_hamburger {
	@include breakpoint('tablet') {
		margin-left: 20 * $px;
	}
}

.header_hamburger {
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@include breakpoint('ipad-land') {
		display: none;
	}

	&-bar {
		display: block;
		height: 2px;
		width: 16 * $px;
		background-color: $dark-grey;

		& + & {
			margin-top: 5px;
		}
	}
}
