@import 'style';

.iframeblock {
	&-title {
		margin-bottom: 50 * $px;
	}

	&-iframecontainer {
		position: relative;
		width: 100%;

		iframe {
			display: block;
			max-width: 100%;

			.mod-fullwidth & {
				width: 100% !important;
			}
		}

		&.mod-aspectratio {
			aspect-ratio: 16/9;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important;
			}
		}
	}
}
