@import 'style';

.products {
	padding: 85 * $px 0;

	&-content {
		position: relative;
	}
}

.filters {
	position: relative;
}

.filterssubcategory {
	position: relative;
}

.filterscategory {
	&-title {
		h5 {
			font-weight: 700;
		}

		&.is-open h5:after {
			transform: rotate(180deg);
			transition: transform 0.25s ease-out;
		}
	}

	&-items {
		padding-top: 25px;

		.form_field {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 0;
		}

		&-item {
			&-subcategories {
				margin-left: 20px;

				.form_field {
					display: block;
				}
			}
		}
	}

	& + & {
		margin-top: 35px;
	}
}
