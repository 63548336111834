@import 'style';

.sociallink {
	position: relative;
	color: #f0f0f0;
	width: 24 * $px;
	height: 24 * $px;
	padding: 4px;
	display: inline-flex;
	vertical-align: top;
	background-color: #c1c1c1;
	transition: background 0.3s ease-out;
	align-items: center;
	justify-content: center;

	& + & {
		margin-left: 15 * $px;
	}

	&.mod-rounded {
		border-radius: 3 * $px;
	}

	&.mod-round {
		border-radius: 100%;
	}

	&:hover {
		&.mod-facebook {
			background-color: $facebook;
		}

		&.mod-flickr {
			background-color: $flickr;
		}

		&.mod-instagram {
			background-color: $instagram;
		}

		&.mod-linkedin {
			background-color: $linkedin;
		}

		&.mod-swivle {
			background-color: $swivle;
		}

		&.mod-twitter {
			background-color: $twitter;
		}

		&.mod-vimeo {
			background-color: $vimeo;
		}

		&.mod-youtube {
			background-color: $youtube;
		}
	}
}
