@import 'style';

.cases {
	overflow: hidden;
	position: relative;
	z-index: 0;
	background-color: $dark-grey;

	&.mod-fullheight {
		@include breakpoint('ipad-land') {
			min-height: calc(100vh - #{140 * $px});
		}
	}

	.mod-preview & {
		min-height: inherit;
	}

	&-bg {
		opacity: 0;
		z-index: -2;
		transition: opacity 0.5s ease-out;

		&.is-active {
			opacity: 1;
			z-index: -1;
		}
	}

	&-row {
		@include breakpoint('ipad-port') {
			display: flex;
		}

		&-carousel {
			flex: 1 1 auto;
		}
	}

	&-carousel {
		position: relative;
		padding: 85 * $px 0;

		&-slides > div {
			align-items: center;
		}

		&-slide {
			position: relative;
			min-width: 100%;
			opacity: 0;
			transition: opacity 0.5s ease-out;

			&.is-active {
				opacity: 1;
			}
		}

		&-prev,
		&-next {
			display: none;
			position: absolute;
			z-index: 1;
			top: 50%;
			transform: translateY(-50%);
			color: white;
			font-size: 32 * $px;

			@include breakpoint('ipad-land') {
				display: block;
			}
		}

		&-prev {
			left: -35 * $px;
		}

		&-next {
			right: -35 * $px;
		}
	}

	&-content {
		position: relative;
		background-color: $dark-grey;
		padding: 50 * $px 25 * $px;
		color: white;

		@include breakpoint('ipad-port') {
			flex: 0 0 auto;
			width: 100%;
			max-width: 330px;
			padding: 50 * $px;
		}

		:global(.wysiwyg) {
			a {
				font-weight: 300;
			}

			b,
			strong {
				color: $blue;
			}

			p {
				font-size: 18 * $px;
				line-height: 1.5;
			}
		}
	}
}

.carouselnav {
	color: white;
	margin-top: -10 * $px;
	margin-left: -20 * $px;
	padding-bottom: 35 * $px;

	@include breakpoint('ipad-land') {
		margin-top: -30 * $px;
		margin-left: -60 * $px;
	}

	&-item {
		display: inline-block;
		vertical-align: top;
		margin-top: 10 * $px;
		padding-left: 20 * $px;

		@include breakpoint('ipad-land') {
			margin-top: 30 * $px;
			padding-left: 60 * $px;
		}

		&-line {
			position: relative;
			overflow: hidden;
			display: block;
			width: 110 * $px;
			height: 4 * $px;
			background-color: #d7d7d7;

			@include breakpoint('ipad-land') {
				width: 220 * $px;
			}

			&:before {
				content: '';
				background-color: $blue;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateX(-100%);
				transform-origin: left center;
				transition: transform 0s ease-out;
			}
		}

		&-title {
			display: none;

			@include breakpoint('ipad-land') {
				display: block;
				text-align: left;
				max-width: 220 * $px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-top: 15 * $px;
			}
		}

		&.is-active {
			.carouselnav-item-line:before {
				transform: translateX(0);
				transition: transform 10s ease-out;
			}
		}
	}
}

.item {
	color: white;

	&-title {
		@include breakpoint('ipad-port') {
			@include gridMaxWidth(10, 9);
		}

		@include breakpoint('ipad-land') {
			@include gridMaxWidth(8, 7);
		}
	}

	&-description {
		max-width: 640 * $px;

		@include breakpoint('ipad-land') {
			max-width: 75%;
			font-size: 18 * $px;
		}
	}

	&-title + &-description {
		margin-top: 15 * $px;
	}

	&-link {
		margin-top: 25 * $px;
	}
}
