@import 'style';

$color: #3f97bd;

.wysiwyg {
	a.link {
		&:after {
			content: '';
			background: url('~assets/images/arrow-right.svg') no-repeat;
			width: 14px;
			height: 11px;
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			transition: transform 0.25s ease-out;
		}

		&:hover {
			border-color: transparent;

			&:after {
				transform: translateX(5px);
			}
		}

		.mod-inherit & {
			color: inherit;
		}
	}

	a.btn {
		cursor: pointer;
		color: white !important;
		line-height: 1.5;
		font-weight: 500 !important;
		display: inline-block;
		vertical-align: top;
		padding: 8 * $px 12 * $px;
		border-radius: 3 * $px;
		background-color: $color;
		border: 1px solid transparent;
		transition: all 0.25s ease-out;

		&:hover {
			background-color: lighten($color, 5%);
		}
	}

	a.btn + a.btn {
		margin-left: 20px;
	}

	&.mod-smallbtn {
		a.btn {
			font-size: 12 * $px;
		}
	}
}
