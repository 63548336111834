@import "style";

.readingroomsearch {
  background-color: white;
  padding: 75 * $px 0;

  &-title {
    margin-bottom: 30 * $px;
  }
}
