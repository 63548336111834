@import "style";

.jobsfilters {

  &-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10*$px;
  }

  &-line {
    margin-top: 15*$px;
  }

  &-tags {
    position: relative;
  }
}

.settings {
  font-size: 1.4em;
  height: 1em;
  width: 1em;
  margin: 5*$px 10*$px;
  position: relative;

  &.is-active {
    cursor: pointer;
  }

  &:hover {
    .settings-notice {
      background: $dark-grey;
      color: white;
    }

    .settings-notice-number {
      display: none;
    }
    .settings-notice-clear {
      display: block;
    }
  }

  &-notice {
    position: absolute;
    background: $blue;
    color: white;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 15*$px;
    height: 15*$px;
    border-radius: 50%;
    opacity: 0.0;
    transition: all 0.15s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    .is-active & {
      opacity: 1.0;
    }

    &-number {
      font-size: 0.5em;
      display: block;
    }
    &-clear {
      font-size: 0.4em;
      display: none;
    }
  }

}


.item {
  margin-left: 10*$px;
  margin-right: 25*$px;
  padding: 8*$px 0;
  color: #9d9d9d;
  border-bottom: 1*$px solid transparent;
  font-size: 18*$px;
  line-height: 19*$px;
  cursor: pointer;
  transition: all 0.15s ease-out;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid currentColor;
  }
  &.is-active {
    color: $blue !important;
  }
  &.is-selected {
    color: $dark-grey;
    text-shadow: -0.015ex 0 currentColor, 0.015ex 0 currentColor;
    border-bottom: 1px solid currentColor;
  }
}

//@deprecated
.settings_alt {
  font-size: 1.4em;
  height: 1em;
  width: 1em;
  margin: 5*$px 10*$px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &-number {
    position: relative;
    z-index: 1;
    color: white;
    height: 100%;
    width: 100%;
    font-size: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue;
    border-radius: 50%;
  }

  &-clear {
    z-index: 0;
    background-color: #e7e7e7;
    width: 10*$px;
    height: 10*$px;
    padding: 5*$px 3*$px 5*$px 15*$px;
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top-right-radius: 5*$px;
    border-bottom-right-radius: 5*$px;

    .icon {
      font-size: 0.3em;
    }
  }
}
