@import 'style';

.iconintro {
	border-top: 1px solid $background;
	padding: 85 * $px 0;

	&-icon {
		max-width: 170 * $px;
		margin: auto;
	}

	&-description {
		font-size: 23 * $px;
		line-height: 1.5;
	}
}
