@import "style";

.jobalerts {
  padding-top: 45*$px;
  max-width: 450*$px;

  &-notice {
    margin-bottom: 20*$px;
    font-size: 20*$px;
  }

  &-form {
    position: relative;
  }

  &-button {
    position: relative;
  }
}
