@import 'style';

.overview {
	background-color: $light-grey;
	padding: 75 * $px 0;

	& + & {
		padding-top: 0;
	}

	&-title {
		margin-bottom: 50 * $px;
	}
}

.videoblock {
	position: relative;
}
