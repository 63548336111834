@import 'style';

.services {
	position: relative;
	background-color: white;
	padding: 80 * $px 0;

	&-bg_container {
		height: 480 * $px !important;
	}

	&-itemscontainer {
		@include breakpoint('ipad-land') {
			margin-top: 110 * $px;
		}
	}
}

.content {
	@include breakpoint('ipad-port') {
		@include gridMaxWidth(10, 9);
	}

	@include breakpoint('ipad-land') {
		font-size: 18 * $px;
		line-height: (30/18);
		max-width: 100%;
	}

	.mod-bg & {
		color: white;
	}
}
