@import 'style';

select.select {
	appearance: none;
	background: url('~assets/images/select-arrow.svg') no-repeat right;

	option {
		color: black;
		font-weight: 100;

		&:hover {
			background-color: $blue;
		}
	}
}

.select {
	border-bottom: 2px solid #fff;
	color: white;
	cursor: pointer;
	font-size: 18 * $px;
	line-height: 1.5;
	padding: 5px 0;
	width: 100%;
	-webkit-transition: 0.3s ease-out;
	transition: 0.3s ease-out;

	&:focus {
		outline: none;
	}

	&:hover,
	&:focus-visible {
		border-bottom: 2px solid hsla(0, 0%, 100%, 0.5);
	}
}

.form {
	position: relative;
}

.request {
	background: $gradient;
	color: white;
	padding: 70 * $px 0;

	&-title {
		margin-bottom: 20 * $px;
	}

	&-identification,
	&-topic {
		&-title {
			@include breakpoint('ipad-land') {
				font-size: 18 * $px;
				line-height: (30/18);
			}
		}
	}

	&-contact {
		margin-top: 50 * $px;

		&-link {
			cursor: pointer;
			color: white;
			display: flex;
			margin-left: auto;
			align-items: center;
			justify-content: flex-end;

			&-text {
				line-height: 1.5;
				text-transform: uppercase;
				margin-right: 10 * $px;
				border-bottom: 1px solid transparent;
				transition: border 0.25s ease-out;
			}

			&-circle {
				color: $blue;
				width: 40 * $px;
				height: 40 * $px;
				border-radius: 100%;
				background-color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.25s ease-out;

				&-icon {
					font-size: 12 * $px;
				}
			}

			&:hover .request-contact-link {
				@include breakpoint('desktop') {
					&-text {
						border-color: white;
					}
					&-circle {
						transform: translateX(10px);
					}
				}
			}
		}
	}
}
