@import 'style';

.backgroundblock {
	position: relative;
	padding: 75 * $px 0;
	background: $gradient;

	h2 {
		&:before {
			display: none;
		}
	}

	&.mod-dark {
		background: $dark-grey;
	}

	&-content {
		color: white;

		.mod-article & {
			padding: 0 20px;
		}

		.mod-darkText & {
			color: $text;
		}

		@include breakpoint('ipad-land') {
			font-size: 18 * $px;
			line-height: (30/18);
		}

		&-left {
			@include breakpoint('ipad-port') {
				@include gridPaddingRight(2, 1);
			}
		}

		&-right {
			@include breakpoint('ipad-port') {
				@include gridPaddingLeft(1, 0);
			}
		}

		&-left,
		&-right {
			p + ul {
				margin-top: 15 * $px;
			}

			ul {
				li {
					padding-left: 55px;

					& + & {
						margin-top: 5 * $px;
					}

					&:before {
						content: '';
						width: 30px;
						height: 20.17px;
						position: relative;
						top: -3px;
						display: inline-block;
						vertical-align: middle;
						margin: 0 25px 0 -55px;
						background: url('~assets/images/list-checkmark-blue.svg') no-repeat;

						.mod-gradient & {
							background: url('~assets/images/list-checkmark-white.svg')
								no-repeat;
						}

						.researchproject & {
							content: '•';
							color: white;
							width: auto;
							height: auto;
							background: none;
						}
					}

					.mod-icon & {
						border-top: 1px solid hsla(0, 0%, 90%, 0.3);
						padding: 5 * $px;
						&:before {
							content: none;
						}
					}
				}
			}
		}
	}
}
