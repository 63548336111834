@import 'style';

.contact {
	background: $gradient;
	padding: 45 * $px 0;
	color: white;

	&-content {
		min-width: 100%;

		h2 {
			&:before {
				display: none;
			}

			& + p {
				margin-top: 10 * $px;
			}
		}

		a {
			color: white;

			&:hover {
				color: white;
				border-color: currentColor;
			}
		}
	}

	&-cta {
		display: flex;
		align-items: center;

		&-text {
			line-height: 1.5;
			text-transform: uppercase;
			margin-right: 10 * $px;
			border-bottom: 1px solid transparent;
			transition: border 0.25s ease-out;
		}

		&-circle {
			color: $blue;
			width: 40 * $px;
			height: 40 * $px;
			border-radius: 100%;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.25s ease-out;

			&-icon {
				font-size: 12 * $px;
			}
		}

		&:hover .contact-cta {
			@include breakpoint('desktop') {
				&-text {
					border-color: white;
				}
				&-circle {
					transform: translateX(10px);
				}
			}
		}
	}
}
