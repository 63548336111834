@import 'style';

.contactform {
	&-request {
		background: $gradient;
		color: white;
		padding: 70 * $px 0;

		&-title {
			margin-bottom: 20 * $px;
		}
	}

	&-form {
		position: relative;
	}
}

.form {
	padding: 75 * $px 0;

	&-title + &-content {
		margin-top: 25 * $px;

		@include breakpoint('ipad-port') {
			margin-top: 50 * $px;
		}
	}

	&-content {
		margin: 0 auto;
		max-width: 640 * $px;

		@include breakpoint('desktop') {
			max-width: calc(100% / (960 / 585));
		}
	}

	&-button {
		text-align: right;
		margin-top: 25 * $px;
	}
}
