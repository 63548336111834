@import 'style';

.relatedpreview {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 255px;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);

	&-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: $gradient-diagonal;

		&-bg {
			transform: scale(1);
			backface-visibility: hidden;
			transition: transform 0.25s ease-out;
		}
	}

	&-label {
		color: white;
		font-size: 12 * $px;
		line-height: 1.5;
		font-weight: 500;
		background-color: rgba($dark-grey, 0.5);
		border-radius: 50 * $px;
		padding: 5 * $px 15 * $px;
		position: absolute;
		left: 30 * $px;
		top: 10 * $px;
	}

	&-content {
		width: 100%;
		color: white;
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 20 * $px 30 * $px;
		transition: background 0.25s ease-out;

		&-title {
			position: relative;
		}

		&-tags {
			margin-bottom: 10 * $px;
		}
	}

	&:hover {
		@include breakpoint('desktop') {
			.relatedpreview {
				&-image-bg {
					transform: scale(1.025);
				}

				&-content {
					background-color: $blue;
				}
			}
		}
	}
}
