@import 'style';

.eloqua {
	&-title {
		margin-bottom: 50 * $px;
	}

	&-formcontainer {
		margin: 0 auto;
		width: 100%;
		max-width: 640 * $px;

		@include breakpoint('desktop') {
			max-width: calc(100% / (960 / 585));
		}
	}

	&-form {
		width: 100%;
		position: relative;
	}
}
