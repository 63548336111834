@import 'style';

.blocks {
	> .block:last-child:not(.alert):not(.articles):not(.backgroundblock):not(
			.cases
		):not(.contact):not(.related):not(.testimonials) {
		padding-bottom: 75px;
	}

	// background-color: $light-grey;

	// &:global(.mod-fullwidth) {
	// 	background-color: white;
	// }

	// & > div {
	// 	& + .backgroundblock,
	// 	& + .contact,
	// 	& + .offices,
	// 	& + .process,
	// 	& + .services.mod-bg,
	// 	& + .testimonials {
	// 		border-top: none;
	// 	}
	// }

	// .textblock + .imageblock {
	// 	border-top: none;
	// 	padding-top: 0;
	// }

	// .imageblock + .textblock {
	// 	padding-top: 0;
	// }

	// .articles,
	// .imageblock,
	// .contact,
	// .backgroundblock,
	// .hero,
	// .herohome,
	// .overview,
	// .process,
	// .services.mod-bg,
	// .testimonials {
	// 	& + div {
	// 		border-top: none;
	// 	}
	// }

	// .hero,
	// .herohome {
	// 	& + .inpagenav {
	// 		border-top: 1px solid $line;
	// 	}
	// }

	// & > div + .blocks2 {
	// 	border-top: 1px solid $line;
	// }

	// .textblock,
	// .imagetext,
	// .links,
	// .list {
	// 	& + .textblock,
	// 	& + .imagetext,
	// 	& + .links,
	// 	& + .list {
	// 		border-top: none;
	// 	}
	// }

	// .links,
	// .list {
	// 	& + .links,
	// 	& + .list {
	// 		margin-top: -80 * $px;
	// 	}
	// }

	// &:global(.mod-fullwidth) {
	// 	& > div {
	// 		padding: 37.5 * $px 0 !important;

	// 		&:first-child {
	// 			padding-top: 0 !important;
	// 		}

	// 		& + div {
	// 			border-top: none;
	// 		}

	// 		.theme_container {
	// 			max-width: 100%;

	// 			&-grid {
	// 				display: none;
	// 			}
	// 		}
	// 	}
	// }
}

.block {
	& + & {
		margin-top: 60px;
	}

	&.hero + &.inpagenav {
		margin-top: 0;
	}

	&.alert,
	&.articles,
	&.backgroundblock,
	&.cases,
	&.centered,
	&.contact,
	&.downloads,
	&.hero,
	&.overview,
	&.testimonials {
		& + &.alert,
		& + &.backgroundblock,
		& + &.cases,
		& + &.centered,
		& + &.contact,
		& + &.downloads,
		& + &.overview,
		& + &.testimonials {
			margin-top: 0;
		}
	}
}
