@import 'style';

.filters {
	background-color: $dark-grey;
	padding: 52.5 * $px 0;
	color: white;

	&-clear {
		position: absolute;
		top: 20*$px;
		right: 0;
	}

	&-tags {
		margin-top: 20 * $px;
	}
}

.item {
	display: inline-block;
	width: calc(50% - #{15 * $px});
	margin: 15 * $px 15 * $px 15 * $px 0;
	color: white;

	@include breakpoint('ipad-port') {
		width: calc(20% - #{15 * $px});
	}

	&-select {
		cursor: pointer;
		border-bottom: 1px solid #fff;
		color: white;
		padding-bottom: 10 * $px;
		width: 100%;
		transition: 0.3s ease-out;

		&:hover {
			border-bottom-color: $blue;
		}

		option {
			color: #000;
			font-weight: 100;
		}
	}

	&-clear {
		position: absolute;
		right: 0;
		white-space: nowrap;
	}
}
