@import 'style';

.jobs {
	background: white;
	position: relative;
	padding-bottom: 85 * $px;

	&-results {
		position: relative;
	}
}

.tabs {
	width: 100%;
	position: relative;

	&-tabs {
		@include breakpoint('tablet') {
			display: flex;
			justify-content: space-between;
		}

		&-group {
			display: flex;
			flex-wrap: wrap;

			&.mod-bookmarked {
				flex: 0 0 auto;
			}

			&.mod-items {
				margin-top: -15 * $px;
			}
		}
	}

	&-item {
		line-height: 1.5;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #e0e0e0;
		margin-top: 15 * $px;
		margin-right: 30 * $px;
		transition: background 0.25s ease-out;

		&:hover {
			background-color: #eeeeee;
		}

		&.is-active {
			background: $blue;
			color: white;
		}

		&-icon {
			font-size: 16px;
			margin-right: 5 * $px;
		}
	}

	&-bookmark {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid transparent;
		margin: 15 * $px 0;

		.bookmarkicon {
			margin-right: 8 * $px;
		}

		&:hover,
		&.is-selected {
			border-bottom: 1px solid currentColor;
		}
	}
}

.bookmarkicon {
	cursor: pointer;
	margin-top: 5 * $px;
	margin-left: 5 * $px;
	transition: color 0.25s ease-out;

	&:hover {
		color: $blue;
		polygon {
			stroke: currentColor;
		}
	}

	&.is-active {
		color: $blue;
		path {
			fill: $blue;
		}
		polygon {
			fill: currentColor;
			stroke: currentColor;
		}
	}
}

.search {
	&-query {
		background: $blue;
		padding: 50 * $px 0;
		color: white;
	}

	&-filters {
		padding: 50 * $px 0;
	}

	&-criteria {
		position: relative;
	}
}

.item {
	margin-top: 50 * $px;

	&-title {
		width: 100%;
		display: flex;
		justify-content: space-between;

		&-link {
			border-bottom: 1px solid transparent;
			transition: border 0.3s ease-out;

			&:hover {
				border-color: $dark-grey;
			}
		}
	}

	&-subtitle {
		font-size: 13 * $px;
		margin-top: 8 * $px;
	}

	&-description {
		font-size: 18 * $px;
		margin-top: 15 * $px;
	}

	&-breadcrumbs {
		margin-top: 20 * $px;
	}
}
