@import 'style';

.imagetext {
	&.mod-slider {
		padding-bottom: 30 * $px;
	}
}

.imgcontainer {
	position: relative;
	width: 100%;
	max-width: 460px;
	aspect-ratio: 16/9;
	margin: 0 auto;

	&-slide {
		position: relative;
		min-width: 100%;
	}

	&-nav {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
		margin-top: 25 * $px;
	}
}

.content {
	@include breakpoint('ipad-land') {
		font-size: 18 * $px;
		line-height: (30/18);
	}
}
