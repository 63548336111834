@import 'style';

.imagepreview {
	&-imgcontainer {
		overflow: hidden;
		position: relative;
		width: 100%;
		display: block;
		aspect-ratio: 16/9;
		background: $gradient-diagonal;
		box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);
		transform: translateY(0);
		transition: transform 0.25s ease-out;

		&-overlay {
			pointer-events: none;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to bottom,
				rgba($dark-grey, 0) 50%,
				rgba($dark-grey, 0.75) 100%
			);
		}

		&-play {
			position: absolute;
			top: 10 * $px;
			left: 15 * $px;
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background-color: rgba($dark-grey, 0.65);

			&:after {
				content: '';
				position: relative;
				left: 1px;
				width: 0;
				height: 0;
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-left: 12px solid white;
			}
		}

		&-type,
		&-date {
			font-size: 12 * $px;
			line-height: 1.5;
			font-weight: 500;
		}

		&-type {
			position: absolute;
			left: 20 * $px;
			bottom: 10 * $px;
		}

		&-date {
			position: absolute;
			right: 20 * $px;
			bottom: 10 * $px;
		}

		// &-label {
		// 	color: white;
		// 	font-size: 12 * $px;
		// 	line-height: 1.5;
		// 	font-weight: 500;
		// 	background-color: rgba($dark-grey, 0.5);
		// 	border-radius: 50 * $px;
		// 	padding: 5 * $px 15 * $px;
		// 	position: absolute;
		// 	left: 10 * $px;
		// 	top: 10 * $px;
		// }

		// &-tags {
		// 	background-color: transparent;
		// 	position: absolute;
		// 	left: 0;
		// 	bottom: 0;
		// 	width: 100%;
		// 	padding: 15 * $px;
		// 	transition: all 0.25s ease-out;
		// }

		&[href]:hover {
			@include breakpoint('desktop') {
				transform: translateY(-20px);

				.imagepreview {
					&-imgcontainer {
						&-tags {
							background-color: $blue;
						}
					}
				}
			}
		}
	}

	&-content {
		display: block;
		padding: 15 * $px;
		padding-bottom: 0;

		&-title {
			font-weight: 300;
			@include m-lineclamp(3, 1.5);
		}

		&-description {
			color: $brown-grey;
			margin-top: 10 * $px;
			@include m-lineclamp();

			.mod-inherit & {
				color: inherit;
			}
		}

		// &-date {
		// 	color: $brown-grey;
		// 	font-size: 12 * $px;
		// 	line-height: 1.5;
		// 	margin-top: 10 * $px;
		// }
	}
}
